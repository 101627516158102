<script>
import { image } from "@/common/config";
import { util } from "@/common/mixins/mix_helper";
export default {
  name: "ClientProfile",
  mixins: [util],
  props: {
    thread: {
      required: true,
    },
    duration: {
      required: true,
    },
  },
  data() {
    return {
      image,
    };
  },
};
</script>

<template>
  <div>
    <div
      class="card shadow-none"
      :class="thread.verified ? 'border-success' : ''"
    >
      <div class="card-body p-0">
        <div class="d-flex align-items-center">
          <div
            class="w-25 d-flex flex-column justify-content-center align-items-center"
          >
            <img
              :src="image.customer"
              alt=""
              class="img-fluid"
              style="width: 2.5em"
            />
            <p
              class="small rounded-pill mt-1 text-success"
              v-if="thread.verified"
            >
              Verified
            </p>
          </div>
          <div class="w-75">
            <p class="font-weight-bold">{{ toHTML(thread.name) }}</p>
            <div
              class="small d-flex align-items-center w-100"
              v-if="thread.verified"
              style="word-break: break-all"
            >
              <i class="material-icons text-success" style="font-size: 1em"
                >verified</i
              >&nbsp;<a
                target="_blank"
                class="text-success"
                :href="`mailto:${thread.email_verified}`"
                >{{ thread.email_verified }}</a
              >
            </div>
            <small v-if="thread.phone">Telp. {{ thread.phone }}</small>
          </div>
        </div>

        <div class="mt-2">
          <p class="small">
            Duration <b>{{ duration }}</b> | {{ thread.ip }} | ID
            <b>{{ thread.subid ? thread.subid : "Refresh" }}</b>
          </p>
          <a
            v-if="thread.referrer !== 'unknown'"
            target="_blank"
            :href="thread.referrer"
            class="small"
            >{{ thread.referrer }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
p {
  margin-bottom: 0;
}
</style>
