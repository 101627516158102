var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col overflow-hidden h-full"},[(!_vm.isLogin)?_c('before-login',{attrs:{"departmentStatus":_vm.departmentStatus}}):_c('after-login',{attrs:{"departmentStatus":_vm.departmentStatus}}),(
      _vm.isLogin &&
      _vm.getConfigJs.isTakeOver &&
      !_vm.getConfigJs.isContinueChatEnabled &&
      !_vm.getConfigJs.isClosed &&
      _vm.getConfigJs.isChatAreaEnabled
    )?_c('TextMessage'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }