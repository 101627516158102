<script>
import ActionPaymentConfirmation from "@/components/customer/body/conversation/actions/ActionPaymentConfirmation.vue";
import ActionPaymentConfirmationCheck from "@/components/customer/body/conversation/actions/ActionPaymentConfirmationCheck.vue";
import { mapGetters } from "vuex";

export default {
  name: "ActionPart",
  components: { ActionPaymentConfirmationCheck, ActionPaymentConfirmation },
  props: {
    message: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getConfigJs"]),
  },
};
</script>

<template>
  <div>
    <ActionPaymentConfirmation
      :message="message"
      v-if="message.action_key === 'payment_confirmation'"
    />
    <ActionPaymentConfirmationCheck
      :message="message"
      v-if="message.action_key === 'payment_confirmation_check'"
    />
  </div>
</template>

<style scoped></style>
