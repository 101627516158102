/**
 * --------------------------------------------------------------
 * time chat or anything
 * mixEpoch : get epoch time
 * mixCalculateTime : hitung brp lama
 * --------------------------------------------------------------
 */

export const waktu = {
  methods: {
    mixNow: function () {
      const dt = new Date();
      let minute = (dt.getMinutes() < 10 ? "0" : "") + dt.getMinutes();
      return `${dt.getHours()}:${minute}`;
    },
    mixEpoch: function () {
      return Math.round(Date.now() / 1000);
    },
    mixEpochToHours: function (epochTime) {
      let date = new Date(parseInt(epochTime) * 1000);
      let minutes =
        date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
      let hour = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
      return hour + ":" + minutes;
    },
    mixGetAgo: function (epochTime) {
      let diff = parseInt(this.mixEpoch()) - parseInt(epochTime);
      return Math.round(diff / 60);
    },
    mixEpochToFullDate(epochTime) {
      let date = new Date(parseInt(epochTime) * 1000);
      const [month, day, year] = [
        date.getMonth(),
        date.getDate(),
        date.getFullYear(),
      ];
      const [hour, minutes] = [date.getHours(), date.getMinutes()];

      let addZero = (int) => {
        return int <= 9 ? "0" + int : int;
      };
      let monthShortNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return `${addZero(day)} ${monthShortNames[month]} ${year} ${addZero(
        hour
      )}:${addZero(minutes)}`;
    },
    mixCalculateTime: function (timeSecond = 0) {
      timeSecond = parseInt(timeSecond);
      let h = Math.floor(timeSecond / 3600);
      let m = Math.floor((timeSecond % 3600) / 60);
      let s = Math.floor((timeSecond % 3600) % 60);

      let hh = h < 10 ? `0${h}` : h;
      let mm = m < 10 ? `0${m}` : m;
      let ss = s < 10 ? `0${s}` : s;
      return `${hh}:${mm}:${ss}`;
    },
    mixToday: function () {
      const d = new Date();
      const month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();

      return `${d.getFullYear()}-${month}-${day}`;
    },
  },
};

/**
 * --------------------------------------------------------------
 * push notify desktop
 * --------------------------------------------------------------
 */
import Push from "push.js";
import { image } from "@/common/config";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

export const alert = {
  methods: {
    /**
     * --------------------------------------------------------------
     * desktop notif showing when permission has been allow
     * @param name : string title of notif
     * @param message : string body of notif
     * @param soundCode : string code for sound
     * @param callback : function for handle onclick notif
     * @param options : any for parsing data to callback
     * --------------------------------------------------------------
     */
    mixAlert: function (
      name = "rw",
      message = "error",
      soundCode = "in",
      callback = null,
      options = null
    ) {
      rwSound.methods.mixSound(1, soundCode);
      let permission = Push.Permission.has();
      if (permission) {
        Push.clear();
        Push.create(name, {
          body: message,
          icon: `./${image.customer}`,
          timeout: 5000,
          onClick: function () {
            window.focus();
            this.close();
            if (typeof callback === "function") {
              callback(options);
            }
          },
        });
      }
    },
    mixSwalBlock: function (message = "", title = "Error", icon = "error") {
      this.$swal({
        title: title,
        html: message,
        icon: icon,
        showConfirmButton: false,
        allowOutsideClick: false,
      });
    },
    mixToast: (message = "This is a toast", config = {}) => {
      let init = {
        close: true,
        escapeMarkup: false,
        duration: 3000,
        ...config,
      };

      Toastify({
        text: message,
        ...init,
      }).showToast();
    },
    toastShow: function (message = "", type = "success") {
      this.mixToast(message, {
        style: {
          background: `var(--${type})`,
        },
      });
    },
  },
};

/**
 * --------------------------------------------------------------
 * for helping to request api
 * dibuat terpusat biar bisa send header sekaligus / token
 * --------------------------------------------------------------
 */
import ApiService from "@/common/API.service";
import ApiPath from "@/common/config/apiurl.config.json";
export const request = {
  data() {
    return {
      URL: ApiPath,
      API: {
        /**
         * --------------------------------------------------------------
         * get request
         * @param endpoint : String
         * @param slug
         * @param config
         * --------------------------------------------------------------
         */
        get: function (endpoint, slug = false, config = {}) {
          return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(endpoint, slug, config)
              .then(({ data }) => {
                resolve(data);
              })
              .catch((error) => {
                reject(error);
              });
          });
        },

        /**
         * --------------------------------------------------------------
         * post request
         * --------------------------------------------------------------
         */
        post: function (endpoint, params, headerToken = true) {
          return new Promise((resolve, reject) => {
            if (typeof params === "object") {
              if (headerToken) {
                ApiService.setHeader();
              }
              ApiService.post(endpoint, params)
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              reject({ message: "Params must be an Object" });
            }
          });
        },

        /**
         * --------------------------------------------------------------
         * for post file ex. image
         * --------------------------------------------------------------
         */
        postFile: function (endpoint, params) {
          return new Promise((resolve, reject) => {
            if (typeof params === "object") {
              ApiService.setHeader();
              ApiService.postFile(endpoint, params)
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              reject({ message: "Params must be an Object" });
            }
          });
        },
      },
    };
  },
};

/**
 * --------------------------------------------------------------
 * for export file .txt transkrip message
 * --------------------------------------------------------------
 */
import { saveAs } from "file-saver";
export const download = {
  methods: {
    download: function (content = "Rumahweb", fileName = "rumahweb") {
      let file = new File([content], `${fileName}.txt`, {
        type: "text/plain;charset=utf-8",
      });
      saveAs(file);
    },
  },
};

/**
 * --------------------------------------------------------------
 * get attachment from cloud storage oss alibaba
 * --------------------------------------------------------------
 */
import { IMAGE_URL, FILES_URL } from "../config";
export const cloudStorage = {
  methods: {
    mixGetImage: function (fileName = "") {
      return IMAGE_URL + "/" + fileName;
    },
    mixGetFiles: function (fileName = "") {
      return FILES_URL + "/" + fileName;
    },
  },
};

/**
 * --------------------------------------------------------------
 * for play sound notifikasi
 * --------------------------------------------------------------
 */
import { Howl } from "howler";
export const rwSound = {
  methods: {
    /**
     * --------------------------------------------------------------
     * for play sound
     * --------------------------------------------------------------
     */
    mixSound: function (volume, soundCode = "in") {
      let sound = new Howl({
        src: this.getSound(soundCode),
        mute: false,
        volume: volume,
      });
      sound.play();
    },

    /**
     * --------------------- LOCAL FUNCTION -------------------------
     * code sound, describe of code
     * in : incoming message
     * st : status agent
     * tf : transfer / add agent to thread
     * --------------------------------------------------------------
     */
    getSound: function (code = "in") {
      let baseSound = "sound/";
      let types = ["in", "sa", "st", "tf", "net-tm", "net-dc"];
      if (!types.includes(code)) {
        code = "in";
      }

      let nameSound = this.getSoundName(code);
      return [
        `${baseSound}${nameSound}.webm`,
        `${baseSound}${nameSound}.mp3`,
        `${baseSound}${nameSound}.wav`,
        `${baseSound}${nameSound}.ogg`,
      ];
    },

    /**
     * --------------------- LOCAL FUNCTION -------------------------
     * get sound name, make sure have 3 extension .mp3, .ogg, .wav
     * --------------------------------------------------------------
     */
    getSoundName: function (code = "in") {
      let name = "incoming_message";
      switch (code) {
        case "sa":
          // name = "inbox_staff_away";
          break;
        case "st":
          name = "agent_status";
          break;
        case "tf":
          name = "transfer";
          break;
        case "net-tm":
          name = "network-timeout";
          break;
        case "net-dc":
          name = "network-error";
          break;
      }
      return name;
    },
  },
};

/**
 * --------------------------------------------------------------
 * for uncategory helper
 * --------------------------------------------------------------
 */
import AppConfig from "@/common/config/app.config.json";
export const util = {
  methods: {
    nl2br: function (str, is_xhtml) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }
      let breakTag =
        is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
      return (str + "").replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        "$1" + breakTag + "$2"
      );
    },

    /**
     * --------------------------------------------------------------
     * decode jwt with ignore key
     * --------------------------------------------------------------
     */
    jwtDecode: function (token) {
      if (
        token !== "" &&
        token.includes(".") &&
        token.split(".").length === 3
      ) {
        let base64Url = token.split(".")[1];
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        let jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        return JSON.parse(jsonPayload);
      }
      return false;
    },

    /**
     * --------------------------------------------------------------
     * handle log request with axios : for get message
     * prefix : for message indicator from page where
     * --------------------------------------------------------------
     */
    handleCatchAxios: function (prefix = "", error, returnMsg = false) {
      let message =
        typeof error.response !== "undefined"
          ? error.response.data.message
          : error.message;

      if (typeof AppConfig.mode !== "undefined" && AppConfig.mode === "demo") {
        let time = waktu.methods.mixNow();
        console.warn(`HandleLog ${prefix} : ${time}`, message);
      }
      return returnMsg ? message : false;
    },

    /**
     * --------------------------------------------------------------
     * escape xss hijacking
     * --------------------------------------------------------------
     */
    escape: function (string = "") {
      let tagsToReplace = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
      };
      return string.replace(/[&<>]/g, function (tag) {
        return tagsToReplace[tag] || tag;
      });
    },

    /**
     * --------------------------------------------------------------
     * generate uuid for message or another need
     * return random string + epoch now
     * --------------------------------------------------------------
     */
    UUID: function () {
      let str = Math.random().toString(36).substring(2);
      let epoch = Math.round(Date.now() / 1000);
      return str + "." + epoch;
    },

    /**
     * --------------------------------------------------
     * decode html entities
     * --------------------------------------------------
     */
    toHTML: (str) => {
      const parser = new DOMParser();
      const element = parser.parseFromString(str, "text/html");
      return element.body.textContent;
    },

    toRupiah(angka, prefix = true) {
      prefix = prefix ? "Rp" : "";
      return prefix + angka.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
