import { render, staticRenderFns } from "./AfterLogin.vue?vue&type=template&id=89dce55e&scoped=true"
import script from "./AfterLogin.vue?vue&type=script&lang=js"
export * from "./AfterLogin.vue?vue&type=script&lang=js"
import style0 from "./AfterLogin.vue?vue&type=style&index=0&id=89dce55e&prod&lang=css"
import style1 from "./AfterLogin.vue?vue&type=style&index=1&id=89dce55e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89dce55e",
  null
  
)

export default component.exports