<template>
  <div class="flex flex-col overflow-hidden h-full">
    <before-login v-if="!isLogin" :departmentStatus="departmentStatus" />
    <after-login v-else :departmentStatus="departmentStatus" />

    <!-- textarea send message -->
    <TextMessage
      v-if="
        isLogin &&
        getConfigJs.isTakeOver &&
        !getConfigJs.isContinueChatEnabled &&
        !getConfigJs.isClosed &&
        getConfigJs.isChatAreaEnabled
      "
    />
  </div>
</template>

<script>
import AfterLogin from "@/components/customer/body/AfterLogin";
import BeforeLogin from "@/components/customer/body/BeforeLogin";
import TextMessage from "@/components/customer/sendMessage";
import { db } from "@/common/firebase/init";
import { waktu, request, alert } from "@/common/mixins/mix_helper";
import { departemenStatus } from "@/common/config";
import { mapGetters } from "vuex";

export default {
  name: "BodyBase",
  mixins: [waktu, request, alert],
  props: {
    isLogin: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    AfterLogin,
    BeforeLogin,
    TextMessage,
  },
  data() {
    return {
      departmentStatus: departemenStatus,
    };
  },
  computed: {
    ...mapGetters(["getConfigJs"]),
  },
  created() {
    /**
     * --------------------------------------------------------------
     * listen firebase department status on/off
     * --------------------------------------------------------------
     */
    db.ref("others/department-status").on("value", (status) => {
      this.departmentStatus = status.val();
    });
  },
};
</script>
