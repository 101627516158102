/**
 * --------------------------------------------------------------
 * module for customers
 * --------------------------------------------------------------
 */
import { waktu, request } from "@/common/mixins/mix_helper";
import { clientCookies } from "@/common/mixins/mix_cookies";
import { Auth, firebase } from "@/common/firebase/init";

/**
 * --------------------------------------------------------------
 * action type
 * --------------------------------------------------------------
 */
export const ISLOGIN = "customerLogin";
export const LOGOUT = "customerLogout";
export const REGISTER = "customerRegister";
export const SendMessage = "customerSendMessage";
export const SendMessageFile = "customerSendMessageFile";

/**
 * --------------------------------------------------------------
 * just ACTION for FIREBASE AUTHENTICATION
 * FIREBASE_LOGIN : login firebase with payload email password
 * FIREBASE_LOGOUT : sign out firebase auth with no payload
 * FIREBASE_STATE_CHANGE : just listen already in or not
 * --------------------------------------------------------------
 */
export const FIREBASE_LOGIN = "FbAuthLogin";
export const FIREBASE_LOGOUT = "FbAuthSignOut";
export const FIREBASE_STATE_CHANGE = "FbAuthStateChange";
export const FIREBASE_ISLOGIN = "FbAuthIsLogin";
export const FIREBASE_TOKEN = "FbTokenLogin";

/**
 * --------------------------------------------------------------
 * mutation type
 * --------------------------------------------------------------
 */
export const SET_LOGIN = "setLogin";

/**
 * --------------------------------------------------------------
 * both action && mutation
 * FLAG_INFO_AGENT : untuk set flag sudah request info agent belum
 * --------------------------------------------------------------
 */
export const TOGGLE_TEXTAREA = "ToggleTextArea";
export const AGENT_INFO = "AgentInfo";
export const SET_EMOJI = "SetEmoji";
export const CONFIG_JS = "ConfigJs";
export const PREPEND_MESSAGE = "prependMessageCustomer";
export const FLAG_INFO_AGENT = "setFlagInfoAgent";

/**
 * --------------------------------------------------------------
 * for manipulate message
 * MSG_SET : set all message
 * MSG_PUSH : append new message
 * MSG_UPDATE : update field
 * --------------------------------------------------------------
 */
export const MSG_SET = "MessageSet";
export const MSG_PUSH = "MessagePush";
export const MSG_UPDATE = "MessageUpdate";

export const CLIENT_LAST_MESSAGE = "clientLastMessage"; // untuk sort firebase

/**
 * --------------------------------------------------------------
 * init const jika ada error request maka ambil default
 * --------------------------------------------------------------
 */
const agentInfoDefault = {
  nama: "Agent",
  jabatan: "Rumahweb",
  dept_name: "Indonesia",
};

export default {
  state: {
    /**
     * --------------------------------------------------------------
     * flag
     * isLogin : flag apakah customer login / tidak
     * _getInfoAgent : request info agent yang handle base on send message
     * --------------------------------------------------------------
     */
    isLogin: false,
    _getInfoAgent: false,

    /**
     * --------------------------------------------------------------
     * for store all message
     * --------------------------------------------------------------
     */
    _allMessage: [],

    /**
     * --------------------------------------------------------------
     * _lastAgent use for info latest agent yang handle percakapan
     * --------------------------------------------------------------
     */
    _lastAgent: agentInfoDefault,

    /**
     * --------------------------------------------------------------
     * toggle textarea pesan customer (enable/disable)
     * --------------------------------------------------------------
     */
    _textarea: {
      disable: false,
      msgEnable: "Tulis pertanyaan Anda ...",
      msgDisable: "Mohon menunggu ...",
    },

    /**
     * --------------------------------------------------------------
     * open : show hide panel emoji
     * select: jika client klik emot
     * emotIcon: icon / emot yang diklik for append message base on select true
     * --------------------------------------------------------------
     */
    _emoji: {
      open: false,
      select: false,
      emotIcon: "",
    },
    /**
     * --------------------------------------------------------------
     * for configurasi tambahan sync dg js
     * isClosed : thread status
     * isChatAreaEnabled : hidden textarea chat from view panel customer
     * uuidForm: custom form with trigger from common question other question
     * --------------------------------------------------------------
     */
    _configCookies: {
      pilihDepartemen: {
        uuid: null,
      },
      rating: {
        uuid: null,
        endtime: null,
      },
      isChatAreaEnabled: false,
      isClosed: false,
      isContinueChatEnabled: false,
      isTakeOver: false,
      uuidForm: null,
    },
    _clientLastMessage: {
      time: waktu.methods.mixEpoch(),
    },
  },
  getters: {
    getIsLogin: function (state) {
      return state.isLogin;
    },
    getTextArea: function (state) {
      return state._textarea;
    },
    getAgent: function (state) {
      return state._lastAgent;
    },
    getAllMessage: function (state) {
      return state._allMessage;
    },
    getEmoji: function (state) {
      return state._emoji;
    },
    getConfigJs(state) {
      return state._configCookies;
    },
    getAllVuexClient(state) {
      return state;
    },
  },
  actions: {
    /**
     * --------------------------------------------------------------
     * @param state
     * @param payload : object {email, name, deptid, message}
     * @return id customer
     * --------------------------------------------------------------
     */
    [REGISTER](state, payload) {
      return new Promise((resolve, reject) => {
        let req = request.data();
        req.API.post(req.URL.auth.customer, payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    /**
     * --------------------------------------------------------------
     * send message dari customer
     * @param state
     * @param payload : Object {message, tipe (text | image)}
     * @return id message
     * --------------------------------------------------------------
     */
    [SendMessage](state, payload) {
      return new Promise((resolve, reject) => {
        try {
          let Cookies = clientCookies.methods.cookiesGet();
          if (
            (typeof Cookies !== "undefined" &&
              Cookies.isLogin &&
              Cookies.customer.id) ||
            (typeof payload.skipCheckCookies !== "undefined" &&
              payload.skipCheckCookies)
          ) {
            if (payload.isFile) {
              let data = new FormData();
              data.append("is_file", true);
              data.append("file", payload.file);
              data.append("data", JSON.stringify(payload));

              payload = data;
            }

            let http = request.data();
            http.API.post(http.URL.client.message_send, payload)
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            reject({ message: "Cookies not found!" });
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    /**
     * --------------------------------------------------------------
     * @param state
     * @param params : formData
     * --------------------------------------------------------------
     */
    [SendMessageFile](state, params) {
      return new Promise((resolve, reject) => {
        let Cookies = clientCookies.methods.cookiesGet();
        if (Cookies && typeof Cookies.threadid !== "undefined") {
          let uuid = () => {
            let str = Math.random().toString(36).substring(2);
            let epoch = Math.round(Date.now() / 1000);
            return str + "." + epoch;
          };
          let message = {
            time: waktu.methods.mixEpoch(),
            tipe: typeof params.tipe !== "undefined" ? params.tipe : "file",
            actor: "customer",
            uuid: uuid(),
            threadid: Cookies.threadid,
          };
          let http = request.data();
          let formData = new FormData();
          formData.append("file", params.message);
          formData.append("data", JSON.stringify(message));
          http.API.postFile(http.URL.message.sendFile, formData)
            .then((data) => {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          reject({ message: "Cookies not found!" });
        }
      });
    },

    /**
     * --------------------------------------------------------------
     * for login firebase authentication
     * set persistence supaya tidak logout sampe dipaksa logout
     * https://firebase.google.com/docs/auth/web/auth-state-persistence#web-version-8
     * --------------------------------------------------------------
     */
    [FIREBASE_LOGIN](state, params) {
      return new Promise((resolve, reject) => {
        Auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            Auth.signInWithEmailAndPassword(params.email, params.password)
              .then((userCredential) => {
                resolve(userCredential);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject({ code: error.code, message: error.message });
          });
      });
    },

    /**
     * --------------------------------------------------------------
     * firebase login dengan custom token
     *
     * --------------------------------------------------------------
     */
    [FIREBASE_TOKEN](state, token) {
      return new Promise((resolve, reject) => {
        Auth.signInWithCustomToken(token)
          .then((userCredential) => {
            resolve(userCredential);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    /**
     * --------------------------------------------------------------
     * for sign out from firebase authentication
     * --------------------------------------------------------------
     */
    [FIREBASE_LOGOUT]() {
      return new Promise((resolve, reject) => {
        Auth.signOut()
          .then(() => {
            resolve("Sign out successfully");
          })
          .catch(() => {
            reject("Sign out failed.");
          });
      });
    },

    /**
     * --------------------------------------------------------------
     * listen state change firebase
     * --------------------------------------------------------------
     */
    [FIREBASE_STATE_CHANGE]() {
      return new Promise((resolve, reject) => {
        Auth.onAuthStateChanged((userCredential) => {
          if (userCredential) {
            resolve(userCredential.toJSON());
          } else {
            reject("Not yet login : stateChange");
          }
        });
      });
    },

    /**
     * --------------------------------------------------------------
     * is user login
     * --------------------------------------------------------------
     */
    [FIREBASE_ISLOGIN]() {
      return new Promise((resolve, reject) => {
        if (Auth.currentUser) {
          resolve(Auth.currentUser.toJSON());
        } else {
          reject({ message: "Auth failed fallback process." });
        }
      });
    },

    /**
     * --------------------------------------------------------------
     * @param state
     * @param payload : boolean
     * --------------------------------------------------------------
     */
    [ISLOGIN](state, payload) {
      state.commit(SET_LOGIN, payload);
    },
    [LOGOUT]() {
      clientCookies.methods.cookiesRemove();
    },
    [TOGGLE_TEXTAREA](state, payload) {
      state.commit(TOGGLE_TEXTAREA, payload);
    },
    [AGENT_INFO](state) {
      let http = request.data();
      http.API.post(http.URL.agent.info, {
        threadid: -1, // just for trigger post api
      })
        .then(({ data }) => {
          state.commit(AGENT_INFO, data.data);
        })
        .catch(() => {
          state.commit(AGENT_INFO, agentInfoDefault);
        });
    },
    [SET_EMOJI]({ commit }, params) {
      commit(SET_EMOJI, params);
    },
    [CONFIG_JS]({ commit }, params) {
      commit(CONFIG_JS, params);
    },

    /**
     * --------------------------------------------------------------
     * manipulate message
     * --------------------------------------------------------------
     */
    [MSG_SET](state, messages) {
      state.commit(MSG_SET, messages);
    },
    [MSG_PUSH]({ commit }, message) {
      commit(MSG_PUSH, message);
    },
    [MSG_UPDATE]({ commit }, payload) {
      commit(MSG_UPDATE, payload);
    },
    [CLIENT_LAST_MESSAGE]({ commit }, params) {
      commit(CLIENT_LAST_MESSAGE, params);
    },
    [PREPEND_MESSAGE]({ commit }, params) {
      commit(PREPEND_MESSAGE, params);
    },

    /**
     * --------------------------------------------------------------
     * params : boolean (true|false)
     * --------------------------------------------------------------
     */
    [FLAG_INFO_AGENT]({ commit }, params = false) {
      commit(FLAG_INFO_AGENT, params);
    },
  },
  mutations: {
    [SET_LOGIN](state, payload) {
      state.isLogin = payload;
    },
    [TOGGLE_TEXTAREA](state, params) {
      state._textarea.disable =
        typeof params.disable !== "undefined" ? params.disable : false;
      state._textarea.msgEnable =
        typeof params.status !== "undefined"
          ? params.msgEnable
          : state._textarea.msgEnable;
      state._textarea.msgDisable =
        typeof params.status !== "undefined"
          ? params.msgDisable
          : state._textarea.msgDisable;
    },
    [AGENT_INFO](state, agent) {
      state._lastAgent = Object.assign(state._lastAgent, agent);
    },
    [SET_EMOJI](state, params) {
      let old = state._emoji;
      state._emoji = Object.assign(old, params);
    },
    [CONFIG_JS](state, params) {
      let old = state._configCookies;
      state._configCookies = Object.assign(old, params);
    },

    /**
     * --------------------------------------------------------------
     * manipulate state message
     * --------------------------------------------------------------
     */
    [MSG_SET](state, messages) {
      state._allMessage = messages;
    },
    [MSG_PUSH](state, message) {
      state._allMessage.push(message);
    },
    [MSG_UPDATE](state, params) {
      state._allMessage[params.index] = Object.assign(
        state._allMessage[params.index],
        params.record
      );
    },
    [CLIENT_LAST_MESSAGE](state, params) {
      state._clientLastMessage = params;
    },
    [PREPEND_MESSAGE](state, params) {
      let current = state._allMessage;
      state._allMessage = [...params, ...current];
    },
    [FLAG_INFO_AGENT](state, params) {
      state._getInfoAgent = params;
    },
  },
};
