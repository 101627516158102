import { alert, request } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import { clientMessage } from "@/common/mixins/mix_message";
import { clientCookies } from "@/common/mixins/mix_cookies";
import Toastify from "toastify-js";

export const actions = {
  mixins: [alert, clientMessage, clientCookies, loader, request],
  methods: {
    /**
     * --------------------------------------------------
     * endchat thread
     * --------------------------------------------------
     */
    end_chat: function (threadid) {
      this.$swal({
        title: "Warning",
        html: "Are you sure want to be close this conversation ?",
        icon: "warning",
        showCancelButton: true,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.loading();
          this.API.post(this.URL.thread.end, {
            threadid: threadid,
          })
            .then(({ data }) => {
              this.$store.dispatch("toggleUserShow", { show: false });
              this.$store.dispatch("DialogMove", { move: true });
              this.$store.dispatch("removeDialogActive");
              this.$store.dispatch("DialogRemove", {
                threadid: data.data.threadid,
              });

              const Toast = Toastify({
                text: "Conversation closed! <b>Undo.</b>",
                duration: 5000,
                close: true,
                escapeMarkup: false,
                onClick: () => {
                  Toast.hideToast();
                  this.undo_endchat(threadid);
                },
              }).showToast();
            })
            .catch((err) => {
              let message =
                typeof err.response !== "undefined"
                  ? err.response.data.message
                  : err.message;
              this.$swal("Error", message, "error");
            })
            .finally(() => {
              this.loading(false);
            });
        }
      });
    },

    /**
     * --------------------------------------------------
     * undo end_chat, trigger ketika after end_chat
     * --------------------------------------------------
     */
    undo_endchat(threadid) {
      this.loading();
      this.API.post(this.URL.thread.endUndo, {
        id: threadid,
      })
        .then(() => {
          this.mixToast("Undo thread close success!", {
            style: {
              background: "var(--success)",
            },
          });
        })
        .catch(() => {
          this.mixToast("Undo thread close failed!", {
            style: {
              background: "var(--danger)",
            },
          });
        })
        .finally(() => {
          this.loading(false);
        });
    },

    /**
     * --------------------------------------------------
     * leave thread jika chat group
     * --------------------------------------------------
     */
    leave_chat(threadid) {
      this.$swal({
        title: "Warning",
        html: "Are you sure want to leave this thread?",
        icon: "warning",
        showCancelButton: true,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.loading();
          this.API.post(this.URL.thread.leave, {
            threadid: threadid,
          })
            .then(({ data }) => {
              this.$store.dispatch("toggleUserShow", { show: false });
              this.$store.dispatch("removeDialogActive");
              this.$store.dispatch("DialogMove", { move: true });
              this.$store.dispatch("DialogRemove", {
                threadid: data.data.threadid,
              });
            })
            .catch((err) => {
              let message =
                typeof err.response !== "undefined"
                  ? err.response.data.message
                  : err.message;
              this.$swal("Error", message, "error");
            })
            .finally(() => {
              this.loading(false);
            });
        }
      });
    },

    /**
     * --------------------------------------------------
     * trigger form konfirmasi pembayaran di panel customer
     * @param threadid :
     * @param data : object {keyword:, uid: opt <uuid message, for update CQ message>}
     * --------------------------------------------------
     */
    handle_workflow(threadid, data) {
      const params = {
        threadid: threadid,
        action: data.keyword,
        uid: typeof data.uid !== "undefined" ? data.uid : false,
      };

      console.log(`run workflow ${threadid}`, [data, params]);

      return new Promise((resolve, reject) => {
        this.loading();
        this.API.post(this.URL.modules.command.action, params)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loading(false);
          });
      });
    },

    /**
     * --------------------------------------------------
     * submit action command workflow
     * --------------------------------------------------
     */
    submit_action_command(message, action_key, options = {}) {
      return new Promise((resolve, reject) => {
        this.clientSendMessage(message, {
          tipe: "action-command",
          action_key: action_key,
          ...options,
        })
          .then(async (data) => {
            resolve(data);
          })
          .catch((error) => reject(error))
          .finally(() => {
            setTimeout(async () => {
              await this.cookiesSyncUpdate({ isChatAreaEnabled: true });
            }, 2000);
          });
      });
    },

    /**
     * --------------------------------------------------
     * cancellation action workflow
     * call from panel customer
     * --------------------------------------------------
     */
    cancel_action(uuidAction, message) {
      return new Promise((resolve, reject) => {
        this.clientSendMessage(message, {
          tipe: "action-command",
          action_key: "action_cancel",
          action_uuid: uuidAction,
        })
          .then(async (data) => {
            await this.cookiesSyncUpdate({ isChatAreaEnabled: true });
            resolve(data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
