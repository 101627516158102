<script>
import { actions } from "@/common/mixins/mix_actions";
import { alert, request } from "@/common/mixins/mix_helper";
import { mapGetters } from "vuex";
import AppConfig from "@/common/config/app.config.json";

export default {
  name: "Command",
  mixins: [actions, alert, request],
  props: {
    message: {
      required: true,
    },
  },
  data() {
    return {
      commands: [],
      selectedIndex: -1,
    };
  },
  methods: {
    onExecute: function (command) {
      console.log("command run : ", command);

      if (command.type === "text") {
        this.$emit("cmdText", command.body);
      } else if (command.type === "action") {
        const actionWorkflow = [
          "payment_confirmation",
          "payment_confirmation_check",
        ];
        if (actionWorkflow.includes(command.code)) {
          this.handle_workflow(this.getDialogActive.id, {
            keyword: command.code,
          })
            .then(() => {
              this.toastShow(
                `Command ${command.title} has been executed successfully.`
              );
            })
            .catch(() => {
              this.toastShow(`Command ${command.title} failed`, "danger");
            });
        } else {
          switch (command.code) {
            case "endchat":
              this.end_chat(this.getDialogActive.id);
              break;

            case "leavechat":
              this.leave_chat(this.getDialogActive.id);
              break;
          }
        }

        this.$emit("cmdText", "");
        this.selectedIndex = -1;
      } else {
        this.toastShow(
          `Type ${command.type} not supported, please report dev team`,
          "danger"
        );
      }
    },

    fetchCommands: function () {
      this.API.get(this.URL.modules.command.index)
        .then(({ data }) => {
          this.commands = data;
        })
        .catch(() => {
          this.commands = [];
        });
    },

    onArrowUpDown(direction = "down") {
      if (direction === "down") {
        this.selectedIndex++;
      } else {
        this.selectedIndex--;
      }

      const commandLength = this.commands.length - 1;

      if (this.selectedIndex < 0) {
        this.selectedIndex = commandLength;
      } else if (this.selectedIndex > commandLength) {
        this.selectedIndex = 0;
      }

      const selectedElement = document.getElementById(
        `command-list-${this.selectedIndex}`
      );
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });

      return this.selectedIndex;
    },

    /**
     * --------------------------------------------------
     * sync command selected with mouse & up down arrow
     * --------------------------------------------------
     */
    onMouseEnter(e) {
      this.selectedIndex = parseInt(e.target.id.replace("command-list-", ""));
    },
  },
  computed: {
    isCommand() {
      return new RegExp(/^\//).test(this.message);
    },
    cmdResult() {
      const command = this.message.replace("/", "");
      return this.commands.filter((cmd) => cmd.title.includes(command));
    },
    isModuleOn() {
      return AppConfig.modules.includes("commands");
    },

    ...mapGetters(["getDialogActive"]),
  },
  mounted() {
    this.fetchCommands();
  },
};
</script>

<template>
  <div
    v-if="isCommand && isModuleOn"
    class="command-main bg-gray-100"
    id="command-main"
    tabindex="0"
    @keydown.up.prevent="onArrowUpDown('up')"
    @keydown.down.prevent="onArrowUpDown('down')"
    @keydown.enter.prevent="onExecute(commands[selectedIndex])"
  >
    <ul class="mb-0">
      <li
        v-for="(i, index) in cmdResult"
        :class="{ selected: index === selectedIndex }"
        :key="i.title"
        :id="`command-list-${index}`"
        @click="onExecute(i)"
        @mouseenter.exact.prevent="onMouseEnter"
      >
        <p class="mb-0 text-dark">{{ i.title }}</p>
        <small class="text-muted"
          ><span :class="i.type === 'action' ? 'text-warning' : 'text-info'">{{
            i.type
          }}</span
          >&bull; {{ i.description }}</small
        >
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.command-main {
  max-height: 10em;
  overflow-y: scroll;
  ul {
    li {
      padding: 0.5em 1em;
      line-height: 0.6em;
      &:hover,
      &.selected {
        cursor: pointer;
        background: #00aae5;
        p {
          color: var(--white) !important;
          font-weight: bold;
        }
        small,
        span {
          color: var(--white) !important;
        }
      }
    }
  }
}
</style>
