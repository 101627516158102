import { render, staticRenderFns } from "./AthreadList.vue?vue&type=template&id=9028b794&scoped=true"
import script from "./AthreadList.vue?vue&type=script&lang=js"
export * from "./AthreadList.vue?vue&type=script&lang=js"
import style0 from "./AthreadList.vue?vue&type=style&index=0&id=9028b794&prod&scoped=true&lang=css"
import style1 from "./AthreadList.vue?vue&type=style&index=1&id=9028b794&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9028b794",
  null
  
)

export default component.exports