import Vue from "vue";
import VueRouter from "vue-router";
import { staffCookies } from "@/common/mixins/mix_cookies";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "customer",
    component: () =>
      import(/* webpackChunkName:"rw-customer" */ "@/views/Customer"),
  },
  {
    path: "/agent",
    name: "agent",
    component: () =>
      import(/* webpackChunkName: "rw-messenger" */ "@/views/BaseChat.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/panel",
    component: () => import("@/views/Panel"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "panel-home",
        component: () => import("@/components/staff/panel/content/HomePage"),
      },

      /**
       * --------------------------------------------------------------
       * list all agents
       * --------------------------------------------------------------
       */
      {
        path: "agent",
        name: "panel-agent",
        component: () => import("@/components/staff/panel/content/ListAgent"),
      },

      /**
       * --------------------------------------------------------------
       * list chat active
       * --------------------------------------------------------------
       */
      {
        path: "chat",
        name: "panel-chat",
        component: () => import("@/components/staff/panel/content/ListChat"),
      },

      /**
       * --------------------------------------------------------------
       * detail chat with params hash subthreadid
       * --------------------------------------------------------------
       */
      {
        path: "chat/:id",
        name: "panel-detail",
        component: () => import("@/components/staff/panel/content/Detail"),
      },

      /**
       * --------------------------------------------------------------
       * history chat agent current
       * --------------------------------------------------------------
       */
      {
        path: "history",
        name: "panel-history",
        component: () => import("@/components/staff/panel/content/History"),
      },
      {
        path: "rating",
        name: "panel-rating",
        component: () =>
          import(
            /* webpackChunkName:"rw-rating" */ "@/components/staff/panel/content/Rating"
          ),
      },
      {
        path: "restrict",
        name: "panel-restrict",
        component: () =>
          import(
            /* webpackChunkName:"rw-restrict" */ "@/components/staff/panel/content/restrict"
          ),
      },
      {
        path: "shortcut",
        name: "panel-shortcut",
        component: () =>
          import(
            /* webpackChunkName:"rw-shortcut" */ "@/components/staff/panel/content/Shortcut"
          ),
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: () =>
      import(/* webpackChunkName:"rw-auth" */ "@/views/Auth.vue"),
  },
  {
    path: "/auth/monitoring",
    name: "AuthMonitoring",
    component: () =>
      import(/* webpackChunkName:"rw-auth-monitor" */ "@/views/AuthMonitoring"),
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/404",
    component: () => import(/* webpackChunkName :"rw-404"*/ "@/views/404"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((url) => url.meta.requiresAuth);
  const staffCookie = staffCookies.data().cookies.get();
  if (
    requiresAuth &&
    (typeof staffCookie.isLogin === "undefined" || !staffCookie.isLogin)
  ) {
    next("/auth");
  } else {
    next();
  }
});

export default router;
