/**
 * --------------------------------------------------------------
 * for flow chat : init 20210408
 * --------------------------------------------------------------
 */
import { staffCookies } from "@/common/mixins/mix_cookies";
import { request, waktu } from "@/common/mixins/mix_helper";
import { lsConfig, lsUnread } from "@/common/mixins/mix_localStorage";
import AppConfig from "@/common/config/app.config.json";

/**
 * --------------------------------------------------------------
 * vuex store general
 * SELECT_THREAD : set current thread active
 * REMOVE_MESSAGE : remove message current sementara dari list array
 * CONNECTED : for listen koneksi internet, firebase anything
 * REMOVE_1ST_MESSAGE : remove message pertama supaya jumlah=limit
 * PREPEND_MESSAGE : prepend message di depan hususnya loadmore
 * SET_UUID_MSG_OPEN : flag for set show/hide more menu message
 * GET_MESSAGE : trigger get message from desktop notif
 * SHORTCUT_CANNED : get shortcut for canned response
 * --------------------------------------------------------------
 */
export const SELECT_THREAD = "ThreadSelected";
export const SNOOZE_TOGGLE = "SnoozeToggle"; // for show/hide panel snooze
export const SHOW_INFO_USER = "toggleUserShow"; // toggle right panel (boolean)
export const APPEND_NEW_MSG = "staffAppendNewMessage"; // append message yg ditulis staff
export const MSG_UPDATE = "staffMessageUpdate";
export const GET_ALL_MESSAGE = "staffGetAllMessage"; // get all message by thread
export const REMOVE_DIALOG_ACTIVE = "removeDialogActive";
export const TEXTAREA_FOCUS = "textareaFocus"; // trigger autofocus textarea message
export const AGENT_LAST_MESSAGE = "agentLastMessage"; // last message-agent untuk sort time firebase
export const REMOVE_MESSAGE = "removeMessage";
export const CONNECTED = "connected";
export const REMOVE_1ST_MESSAGE = "removeFirstMessage";
export const APPEND_MSGSTAFF_LOG = "appendMessageStaffLog";
export const REMOVE_MSGSTAFF_LOG = "removeMessageStaffLog";
export const START_CRON = "startCron";
export const PREPEND_MESSAGE = "prependMessage";
export const SET_UUID_MSG_OPEN = "setUuidMessageMoreOpened";
export const GET_MESSAGE = "getMessage";
export const SHORTCUT_CANNED = "ShortcutCanned";
export const TOGGLE_IS_ONLINE = "toggleIsOnline";
export const TOGGLE_IS_KNOWLEDGE_BASE = "isKnowledgeBase"; // trigger message knowledge base

/**
 * --------------------------------------------------------------
 * manipulasi agent active di thread yang sedang dibuka
 * --------------------------------------------------------------
 */
export const REMOVE_AGENT = "removeActiveAgent"; // ex. leave thread
export const SEARCH_MESSAGE = "SearchMessage";
export const APPEND_NEW_AGENT = "appendNewAgent"; //for trigger when add agent

/**
 * --------------------------------------------------------------
 * this is const for manipulate thread/dialog purpose @210421
 * THREAD_SET : for set / replace all thread
 * THREAD_APPEND : append new thread to all thread
 * THREAD_UPDATE : update child field
 * THREAD_REMOVE: remove thread
 * THREAD_MOVE : untuk move thread di current tab
 * THREAD_OPEN_UPDATE : update data thread yang sedang dibuka / handle
 * --------------------------------------------------------------
 */
export const THREAD_SET = "DialogSet";
export const THREAD_APPEND = "DialogAppend";
export const THREAD_UPDATE = "DialogUpdate";
export const THREAD_REMOVE = "DialogRemove";
export const THREAD_UPDATE_TIME = "DialogUpTime";
export const THREAD_MOVE = "DialogMove";
export const THREAD_OPEN_UPDATE = "DialogOpenUpdate";

/**
 * --------------------------------------------------------------
 * for manipulate snooze DI vuex bukan JS
 * SNOOZE_SET : replace all snooze case ex. sync js and vuex when reload
 * SNOOZE_PUSH : push new snooze
 * SNOOZE_REMOVE : remove snooze and append new alert
 * SNOOZE_REMOVE_ALERT : remove alert indicator by threadid
 * --------------------------------------------------------------
 */
export const SNOOZE_SET = "SnoozeSet";
export const SNOOZE_PUSH = "SnoozePush";
export const SNOOZE_REMOVE = "SnoozeRemove";
export const SNOOZE_REMOVE_ALERT = "SnoozeRemoveAlert";

/**
 * --------------------------------------------------------------
 * draw for loadmore message (agent & customer)
 * --------------------------------------------------------------
 */
export const DRAW = "Draw";

export default {
  /**
   * --------------------------------------------------------------
   * for storage data, init variable
   * --------------------------------------------------------------
   */
  state: {
    /**
     * --------------------------------------------------------------
     * _userInfoOpen : toggle show/hide client info
     * _dialogActive : data thread yg sedang aktif
     * _listAgents : list agent yg handle current thread
     * _chatActive : list all message current
     * _chatLog : save log message incoming
     * _startCron : counter cron per menit
     * _uuidShowMoreMsg : uuid for flag btn more option message
     * --------------------------------------------------------------
     */
    _userInfoOpen: false,
    _dialogActive: {},
    _listAgents: [],
    _chatActive: [],
    _chatLog: [],
    _startCron: 0,
    _uuidShowMoreMsg: null,

    /**
     * --------------------------------------------------------------
     * pencarian keyword di message
     * result: for store result search
     * selected: if clicked on result message
     * --------------------------------------------------------------
     */
    _searchMessage: {
      formOpen: false,
      keyword: "",
      result: [],
      selected: "",
    },

    /**
     * --------------------------------------------------------------
     * for manipulate threads / dialogs
     * all : for showing to list dialog
     * move: for trigger update kategori thread like a pending client etc
     * textareaFocus : trigger for set autofocus in textarea sendmessage
     * textareaClear : textarea dikosongkan atau tidak
     * getMessage : untuk trigger getmessage() jika true
     * --------------------------------------------------------------
     */
    _threads: {
      all: [],
      move: false,
      textareaFocus: false,
      textareaClear: true,
      getMessage: {
        get: false,
        threadid: null,
      },
    },

    /**
     * --------------------------------------------------------------
     * data for snooze message
     * --------------------------------------------------------------
     */
    _snooze: {
      open: false,
      record: lsConfig.methods.mixConfigGetter().snoozeTime,
    },

    /**
     * --------------------------------------------------------------
     * storage for snooze active
     * alert : save threadid jika sudah selesai, indicator alert dialog
     * record : kelengkapan data snooze
     * --------------------------------------------------------------
     */
    _snoozeActive: {
      record: [],
      alert: [],
    },

    /**
     * --------------------------------------------------------------
     * store last message yang masuk, untuk sort time firebase
     * --------------------------------------------------------------
     */
    _agentLastMessage: {
      time: waktu.methods.mixEpoch(),
    },

    /**
     * --------------------------------------------------------------
     * menampilkan notif disconnect saat init baru untuk firebase
     * menggunakan cron untuk cek connect / not
     * firebase (true :connect, false :disconnect)
     * --------------------------------------------------------------
     */
    _isConnect: {
      firebase: true,
    },

    /**
     * --------------------------------------------------------------
     * for readmore message, default 1
     * value : nilai draw
     * last : apakah message terakhir (blum pake),
     * lastmsgid : id message terakhir di vuex (agent)
     * lastmsgid : akan diisi last time message (customer)
     * --------------------------------------------------------------
     */
    _drawMessage: {
      value: 1,
      last: false,
      lastmsgid: false,
    },
    _shortcutCanned: [],

    /**
     * --------------------------------------------------
     * _isOnline : hanya digunakan di panel chat, sisanya ambil dari cookies saja
     * _isKbMessage : knowledge base message (based on copas text)
     * _draftMessages: ganti buka thread message tidak hilang (hilang ketika refresh)
     * --------------------------------------------------
     */
    _isOnline: false,
    _isKnowledgeBaseMessage: false,
  },

  /**
   * --------------------------------------------------------------
   * for get state what we want.
   * --------------------------------------------------------------
   */
  getters: {
    getUserInfoOpen: function (state) {
      return state._userInfoOpen;
    },
    getDialogActive: function (state) {
      return state._dialogActive;
    },
    getListAgent: function (state) {
      return state._listAgents;
    },
    getChat: function (state) {
      return state._chatActive;
    },
    getChatLog: function (state) {
      return state._chatLog;
    },
    getThreads: function (state) {
      return state._threads;
    },
    getSearch: function (state) {
      return state._searchMessage;
    },
    getSnooze: function (state) {
      return state._snooze;
    },
    getSnoozeActive: function (state) {
      return state._snoozeActive;
    },
    getAllVuexAgent: function (state) {
      return state;
    },
    getStartCron: function (state) {
      return state._startCron;
    },
    getUuidOpened: function (state) {
      return state._uuidShowMoreMsg;
    },
    getIsKnowledgeBaseMessage: function (state) {
      return state._isKnowledgeBaseMessage;
    },
  },

  /**
   * --------------------------------------------------------------
   * for actions, to manipulate data before send mutation
   * --------------------------------------------------------------
   */
  actions: {
    /**
     * --------------------------------------------------------------
     * when agent click thread/dialog
     * @param state
     * @param params: object {dialog, agents}
     * --------------------------------------------------------------
     */
    [SELECT_THREAD](state, params) {
      staffCookies.data().cookies.add({
        threadOpen: { id: params.dialog.id, fbid: params.dialog.firebaseid },
      });
      state.commit(SELECT_THREAD, params);
    },

    /**
     * --------------------------------------------------------------
     * just for toggle show/hide userinfo
     * --------------------------------------------------------------
     */
    [SHOW_INFO_USER](state, params) {
      if (typeof params !== "undefined" && typeof params.show !== "undefined") {
        state.commit(SHOW_INFO_USER, params);
      } else {
        state.commit(SHOW_INFO_USER);
      }
    },

    /**
     * --------------------------------------------------------------
     * get all message based on thread id
     * @param state
     * @param params: object {threadid : int}
     * --------------------------------------------------------------
     */
    [GET_ALL_MESSAGE](state, params) {
      return new Promise((resolve, reject) => {
        let http = request.data();
        params.limit = AppConfig.limitMessage;
        params.isAgent = true;
        http.API.post(http.URL.message.allMessage, params)
          .then(({ data }) => {
            let record = data.data.record;
            if (typeof params.isLoadMore !== "undefined") {
              /**
               * --------------------------------------------------------------
               * hidden button load more
               * --------------------------------------------------------------
               */
              // if (record.length < AppConfig.limitMessage) {
              //   state.commit(DRAW, { type: "setLast", value: true });
              // }
              state.commit(PREPEND_MESSAGE, record);
            } else {
              state.commit(GET_ALL_MESSAGE, record);
            }

            /**
             * --------------------------------------------------------------
             * berlaku untuk refresh dan btn loadmore
             * jika record != set last id for loadmore
             * --------------------------------------------------------------
             */
            if (record.length !== 0) {
              state.commit(DRAW, {
                type: "setLastMessageID",
                value: parseInt(record[0].id),
              });
            }
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    /**
     * --------------------------------------------------------------
     * append new message
     * @params : object dari message ex {time:...,etc}
     * cek apakah sudah ada belum base on uid
     * --------------------------------------------------------------
     */
    [APPEND_NEW_MSG](state, params) {
      state.commit(APPEND_NEW_MSG, params);
    },
    [MSG_UPDATE]({ commit }, params) {
      commit(MSG_UPDATE, params);
    },

    /**
     * --------------------------------------------------------------
     * log staff message
     * @params : object dari message
     * --------------------------------------------------------------
     */
    [APPEND_MSGSTAFF_LOG](state, params) {
      state.commit(APPEND_MSGSTAFF_LOG, params);
    },
    [REMOVE_MSGSTAFF_LOG](state, params) {
      state.commit(REMOVE_MSGSTAFF_LOG, params);
    },

    /**
     * --------------------------------------------------------------
     * cron global variable
     * @params : object dari message
     * --------------------------------------------------------------
     */
    [START_CRON](state, value) {
      state.commit(START_CRON, value);
    },

    /**
     * --------------------------------------------------------------
     * append new agent when add agent panel staff
     * APPEND_NEW_AGENT: tambah agent active
     * REMOVE_AGENT : params arrAgent = [agentid, agentid2, etc]
     * --------------------------------------------------------------
     */
    [APPEND_NEW_AGENT](state, agent) {
      state.commit(APPEND_NEW_AGENT, agent);
    },
    [REMOVE_AGENT](state, arrAgent) {
      state.commit(REMOVE_AGENT, arrAgent);
    },

    /**
     * --------------------------------------------------------------
     * for remove dialog ex. leave thread, end thread
     * --------------------------------------------------------------
     */
    [REMOVE_DIALOG_ACTIVE](state) {
      staffCookies.data().cookies.add({ threadOpen: {} });
      state.commit(REMOVE_DIALOG_ACTIVE, {});
    },

    /**
     * --------------------------------------------------------------
     * for dialogs or threads
     * THREAD_SET : params all dialogs
     * THREAD_APPEND: 1 thread want to be append
     * THREAD_UPDATE: params {index: x, record: {data perubahan}}
     * THREAD_REMOVE: params {threadid: x}
     * --------------------------------------------------------------
     */
    [THREAD_SET](state, threads) {
      state.commit(THREAD_SET, threads);
    },
    [THREAD_APPEND](state, thread) {
      state.commit(THREAD_APPEND, thread);
    },
    [THREAD_UPDATE](state, params) {
      state.commit(THREAD_UPDATE, params);
    },
    [THREAD_REMOVE]({ commit, state }, params) {
      let filter = state._threads.all.filter((thread) => {
        return thread.id !== params.threadid;
      });
      commit(THREAD_SET, filter);
    },
    [THREAD_UPDATE_TIME]({ commit, state }, params) {
      state._threads.all.forEach((value, index) => {
        commit(THREAD_UPDATE_TIME, {
          now: params.now,
          key: index,
        });
      });
    },
    [THREAD_MOVE]({ commit }, params) {
      commit(THREAD_MOVE, params);
    },
    [THREAD_OPEN_UPDATE]({ commit }, params) {
      commit(THREAD_OPEN_UPDATE, params);
    },

    /**
     * --------------------------------------------------------------
     * params {openForm:x, keyword:x, record:x, selected:x} all optional
     * --------------------------------------------------------------
     */
    [SEARCH_MESSAGE]({ commit, state }, params) {
      let append = Object.assign(state._searchMessage, params);
      commit(SEARCH_MESSAGE, append);
    },
    [SNOOZE_TOGGLE](state, params) {
      state.commit(SNOOZE_TOGGLE, params);
    },

    /**
     * --------------------------------------------------------------
     * manipulate snooze vuex
     * --------------------------------------------------------------
     */
    [SNOOZE_SET]({ commit }, params) {
      commit(SNOOZE_SET, params);
    },
    [SNOOZE_PUSH]({ commit, state }, params) {
      let record = state._snoozeActive.record;
      if (record.length === 0) {
        record.push(params);
        commit(SNOOZE_PUSH, record);
      } else {
        let snooze = record.filter((item) => item.threadId !== params.threadId);
        snooze.push(params);
        commit(SNOOZE_PUSH, snooze);
      }
    },
    [SNOOZE_REMOVE]({ commit, state }, threadid) {
      let remove = state._snoozeActive.record.filter(
        (old) => old.threadId !== threadid
      );
      commit(SNOOZE_REMOVE, { data: remove, threadid: threadid });
    },
    [SNOOZE_REMOVE_ALERT]({ commit, state }, params) {
      let threadid = params.threadid;
      if (params.removeSnooze) {
        // ============== jika balas tapi snooze belum habis ================
        let remove = state._snoozeActive.record.filter(
          (old) => old.threadId !== threadid
        );
        commit(SNOOZE_REMOVE, { data: remove, threadid: threadid });
      }

      // ============== remove alert ================
      if (state._snoozeActive.alert.includes(threadid)) {
        let remove = state._snoozeActive.alert.filter((id) => id !== threadid);
        commit(SNOOZE_REMOVE_ALERT, remove);
      }
    },
    [TEXTAREA_FOCUS]({ commit }, params) {
      commit(TEXTAREA_FOCUS, params);
    },
    [AGENT_LAST_MESSAGE]({ commit }, params) {
      commit(AGENT_LAST_MESSAGE, params);
    },
    [REMOVE_MESSAGE]({ commit, state }, params) {
      let removeMessage = state._chatActive.filter(
        ({ uuid }) => uuid !== params.uuid
      );
      commit(GET_ALL_MESSAGE, removeMessage);
    },

    /**
     * --------------------------------------------------------------
     * @init : 2021-09-18
     * @params tergantung state ex. {firebase:false, etc}
     * --------------------------------------------------------------
     */
    [CONNECTED]({ commit }, params) {
      commit(CONNECTED, params);
    },

    /**
     * --------------------------------------------------------------
     * remove pesan pertama chat menjaga tetap sedikit
     * --------------------------------------------------------------
     */
    [REMOVE_1ST_MESSAGE]({ state, commit }) {
      let limit = AppConfig.limitMessage;
      if (state._chatActive.length >= limit) {
        state._chatActive.splice(0, 1);
        let x = state._drawMessage.lastmsgid + 1;
        commit(DRAW, { type: "setLastMessageID", value: x });
      }
      commit(GET_ALL_MESSAGE, state._chatActive);
    },

    /**
     * --------------------------------------------------------------
     * init 2021-09-22
     * --------------------------------------------------------------
     */
    [DRAW]({ commit }, params) {
      commit(DRAW, params);
    },

    [SET_UUID_MSG_OPEN]({ commit }, uuid) {
      commit(SET_UUID_MSG_OPEN, uuid);
    },

    /**
     * --------------------------------------------------------------
     * @param commit
     * @param params : object {threadid:x, get : boolean}
     * --------------------------------------------------------------
     */
    [GET_MESSAGE]({ commit }, params) {
      if (
        typeof params.threadid !== "undefined" &&
        typeof params.get !== "undefined"
      ) {
        commit(GET_MESSAGE, params);
      }
    },

    [SHORTCUT_CANNED]({ commit }) {
      let http = request.data();
      return new Promise((resolve, reject) => {
        http.API.get(http.URL.canned.shortcut)
          .then(({ data }) => {
            commit(SHORTCUT_CANNED, data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },

  /**
   * --------------------------------------------------------------
   * for mutation, send to state
   * --------------------------------------------------------------
   */
  mutations: {
    [SHOW_INFO_USER](state, params) {
      if (typeof params !== "undefined" && typeof params.show !== "undefined") {
        state._userInfoOpen = params.show;
      } else {
        state._userInfoOpen = !state._userInfoOpen;
      }
    },
    [SELECT_THREAD](state, params) {
      state._listAgents = params.agents;
      state._dialogActive = params.dialog;
    },
    [GET_ALL_MESSAGE](state, params) {
      state._chatActive = params;
    },
    [PREPEND_MESSAGE](state, params) {
      let current = state._chatActive;
      state._chatActive = [...params, ...current];
    },
    [APPEND_NEW_MSG](state, params) {
      state._chatActive.push(params);
    },
    [MSG_UPDATE](state, params) {
      state._chatActive[params.index] = Object.assign(
        state._chatActive[params.index],
        params.record
      );
    },
    [APPEND_NEW_AGENT](state, agent) {
      state._listAgents.push(agent);
    },
    [REMOVE_AGENT](state, agents) {
      let arr = [];
      agents.forEach((id) => {
        state._listAgents.filter((old) => {
          if (id === old.id) {
            arr.push(old);
          }
        });
      });
      state._listAgents = arr;
    },
    [REMOVE_DIALOG_ACTIVE](state, params) {
      state._dialogActive = params;
    },
    [SEARCH_MESSAGE](state, params) {
      state._searchMessage = params;
    },
    [SNOOZE_TOGGLE](state, params) {
      state._snooze.open = params.open;
    },
    /**
     * --------------------------------------------------------------
     * for dialogs or threads
     * --------------------------------------------------------------
     */
    [THREAD_SET](state, threads) {
      state._threads.all = threads;
    },
    [THREAD_APPEND](state, params) {
      let thread = params.thread;
      let threadNew = {
        id: thread.id,
        time: thread.last_time,
        name: thread.custname,
        reply: thread.is_reply,
        email: thread.email,
        unread: true,
        message: thread.last_message,
        ip: thread.ip,
        referrer: thread.referrer,
        firebaseid: params.firebaseid,
        custid: thread.custid,
        phone: thread.phone,
        subid: thread.subid,
        email_verified: thread.email_verified,
        verified: thread.verified,
      };
      state._threads.all.push(threadNew);
    },
    [THREAD_UPDATE](state, params) {
      // manipulate unread js localstorage
      if (typeof params.threadid !== "undefined") {
        if (typeof params.record.reply !== "undefined") {
          if (!params.record.reply) {
            lsUnread.methods.mixUnreadPush(params.threadid);
          } else {
            lsUnread.methods.mixUnreadRemove(params.threadid);
          }
        }
      }
      if (typeof params.record.name === "undefined") {
        delete params.record.name;
      }
      state._threads.all[params.index] = Object.assign(
        state._threads.all[params.index],
        params.record
      );
    },
    [THREAD_UPDATE_TIME](state, params) {
      state._threads.all[params.key].now = params.now;
    },
    [THREAD_MOVE](state, params) {
      state._threads.move = params.move;
    },
    [THREAD_OPEN_UPDATE](state, params) {
      state._dialogActive = {
        ...state._dialogActive,
        ...params,
      };
    },

    /**
     * --------------------------------------------------------------
     * for manipulate snooze interaksi with state
     * --------------------------------------------------------------
     */

    [SNOOZE_SET](state, params) {
      state._snoozeActive.record = params.record;
      state._snoozeActive.alert = params.alert;
    },
    [SNOOZE_PUSH](state, params) {
      state._snoozeActive.record = params;
    },
    [SNOOZE_REMOVE](state, params) {
      state._snoozeActive.record = params.data; // update data terbaru
      state._snoozeActive.alert.push(params.threadid); // push new alert indicator
    },
    [SNOOZE_REMOVE_ALERT](state, alerts) {
      state._snoozeActive.alert = alerts;
    },
    [TEXTAREA_FOCUS](state, params) {
      state._threads.textareaFocus = params.focus;
      state._threads.textareaClear = params.clear ? params.clear : false;
    },
    [AGENT_LAST_MESSAGE](state, params) {
      console.warn(`Last time message`, params.time);
      params = Object.assign(state._agentLastMessage, params);
      state._agentLastMessage = params;
    },
    [CONNECTED](state, params) {
      state._isConnect = params;
    },
    /**
     * --------------------------------------------------------------
     * untuk chatlog
     * --------------------------------------------------------------
     */
    [APPEND_MSGSTAFF_LOG](state, params) {
      state._chatLog[params.uuid] = params.time;
    },
    [REMOVE_MSGSTAFF_LOG](state, params) {
      delete state._chatLog[params.uuid];
    },

    /**
     * --------------------------------------------------------------
     * cron global variable
     * @params : object dari message
     * --------------------------------------------------------------
     */
    [START_CRON](state) {
      state._startCron = state._startCron + 1;
    },
    [DRAW](state, params) {
      let init = state._drawMessage;
      switch (params.type) {
        case "increase":
          init.value = state._drawMessage.value + 1;
          break;
        case "decrease":
          init.value = state._drawMessage.value - 1;
          break;
        case "reset":
          init.value = 1;
          //init.last = false;
          break;
        case "setLast":
          init.last = params.value;
          break;
        case "setLastMessageID":
          init.lastmsgid = params.value;
          break;
      }
      state._drawMessage = init;
    },
    [SET_UUID_MSG_OPEN](state, uuid) {
      state._uuidShowMoreMsg = uuid;
    },

    [GET_MESSAGE](state, params) {
      state._threads.getMessage.get = params.get;
      state._threads.getMessage.threadid = params.threadid;
    },

    [SHORTCUT_CANNED](state, params) {
      state._shortcutCanned = params;
    },
    [TOGGLE_IS_ONLINE](state, status = false) {
      state._isOnline = status;
    },
    [TOGGLE_IS_KNOWLEDGE_BASE](state, status = false) {
      state._isKnowledgeBaseMessage = status;
    },
  },
};
