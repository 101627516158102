<script>
import { request } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import { clientCookies } from "@/common/mixins/mix_cookies";

export default {
  name: "ModuleRating",
  mixins: [clientCookies, loader, request],
  data() {
    return {
      form: {
        ratingValue: "",
        ratingMessage: "",
        tellMeMore: [],
      },
      ratingFace: {
        tear: "1",
        sad: "2",
        meh: "3",
        smile: "4",
        laugh: "5",
      },
      tellMeMore: {
        satu: [
          "Tidak ramah",
          "Tidak memberi solusi",
          "Bahasanya belibet",
          "Tidak membantu",
        ],
        dua: [
          "Kurang ramah",
          "Kurang memberi solusi",
          "Kurang pengetahuan",
          "Kurang membantu",
        ],
        global: [
          "Tidak membantu",
          "Masalah belum selesai",
          "Tidak mudah dipahami",
          "Respon Lambat",
          "Tidak Ramah",
        ],
      },
    };
  },
  methods: {
    sendRating: function () {
      if (this.form.ratingValue !== "") {
        let tellMeMore = this.form.tellMeMore;
        let text = "";
        Array.from(tellMeMore).forEach((a) => {
          text += `- ${a}\n`;
        });

        if (this.form.ratingMessage.trim() !== "") {
          if (["1", "2"].includes(this.form.ratingValue) && tellMeMore.length) {
            text += "catatan: ";
          }
          text += `${this.form.ratingMessage}`;
        }

        if (["1", "2"].includes(this.form.ratingValue) && !text.trim().length) {
          this.$swal(
            "Warning",
            "Mohon mengisi ulasan terlebih dahulu.",
            "warning"
          );
          return;
        }

        let roti = this.getCookies();
        if (
          roti &&
          typeof roti.customer !== "undefined" &&
          typeof roti.customer.id !== "undefined"
        ) {
          this.loading();
          this.API.post(this.URL.thread.rating, {
            star: this.form.ratingValue,
            message: text,
          })
            .then(() => {
              this.handleRatingSuccess();
            })
            .catch(() => {
              /**
               * --------------------------------------------------------------
               * walaupun gagal tetep dikasih success message sesuai doc
               * --------------------------------------------------------------
               */
              this.handleRatingSuccess();
            })
            .finally(() => {
              this.loading(false);
            });
        } else {
          this.handleLogoutFirebase();
        }
      } else {
        this.$swal("Warning", "Please choose rating first.", "warning");
      }
    },

    /**
     * --------------------------------------------------------------
     * helper for trigger if send rating
     * --------------------------------------------------------------
     */
    handleRatingSuccess() {
      let text = "Terimakasih, rating Anda sudah kami terima";
      if (["1", "2"].includes(this.form.ratingValue)) {
        if (
          this.form.tellMeMore.includes("Tidak membantu") ||
          this.form.tellMeMore.includes("Masalah belum selesai")
        ) {
          text = `<p class="text-left">Terima kasih atas review Anda. Sebagai informasi, beberapa request dan keluhan tidak dapat dilayani melalui chat karena:</p>
          <ol style="padding-left: 1em;">
            <li class="text-left">Membutuhkan waktu analisa yang lebih lama; atau</li>
            <li class="text-left">Membutuhkan otorisasi via email dari pemilik akun karena Rumahweb harus melakukan perubahan pada akun pelanggan; atau</li>
            <li class="text-left">Masalah harus di-eskalasikan ke technical support level 2 atau 1;</li>
          </ol>
          <p class='text-left'>Jika Anda telah mengirimkan permintaan bantuan melalui email / trouble ticket, silahkan menunggu respon dari kami. Sekali lagi mohon maaf atas ketidaknyamanannya.</p>`;
        }
      }

      let x = { rating: { uuid: null, endtime: null } };
      this.cookiesAdd(x); // js
      this.$store.dispatch("ConfigJs", x); // vuex

      this.form.ratingMessage = "";
      this.$swal({ title: "Success", html: text, icon: "success" });
    },

    getCookies: function () {
      let roti = this.cookiesGet();
      if (roti) {
        return roti;
      } else {
        this.cookies.remove();
        location.reload();
      }
      return false;
    },

    tellMeChecked(word) {
      return this.form.tellMeMore.includes(word);
    },
  },
  computed: {
    tellMeMoreShow() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.tellMeMore = [];
      let val = this.form.ratingValue;
      return ["1", "2"].includes(val);
    },

    tellMeMoreStar() {
      let star = this.form.ratingValue;
      switch (star) {
        case "1":
          star = "global";
          break;
        case "2":
          star = "global";
          break;
      }
      return star;
    },
  },
};
</script>

<template>
  <div>
    <p class="text-center font-bold">
      Seberapa puas Anda dengan pelayanan sesi chat ini?
    </p>
    <div class="rating flex justify-center gap-3 mb-2">
      <label v-for="(value, faceName) in ratingFace" :key="faceName">
        <input type="radio" v-model="form.ratingValue" :value="value" />
        <img
          :src="`/img/svg/emot-rate/${faceName}.svg`"
          :alt="faceName"
          class="emot-fill"
        />
        <img
          :src="`/img/svg/emot-rate/${faceName}-line.svg`"
          :alt="faceName"
          class="emot-line"
        />
      </label>
    </div>

    <template v-if="tellMeMoreShow">
      <div class="small pl-1">Beri tahu kami, apa yang kurang :</div>
      <div
        class="tell-me-more btn-group-toggle btn-group-sm my-1 d-flex align-items-center flex-wrap"
      >
        <label
          class="btn"
          :class="tellMeChecked(i) ? 'btn-dark' : 'btn-outline-dark'"
          v-for="i in tellMeMore[tellMeMoreStar]"
          :key="i"
        >
          <input type="checkbox" :value="i" v-model="form.tellMeMore" />
          <div v-html="i"></div>
        </label>
      </div>
    </template>

    <textarea
      @keyup.enter="sendRating()"
      class="form-control"
      v-model="form.ratingMessage"
      placeholder="Tinggalkan penilaian dan ulasanmu disini"
    />
    <br />
    <button class="btn btn-info rw-bg-3 border-0 btn-block" @click="sendRating">
      Kirim
    </button>
  </div>
</template>

<style scoped>
.tell-me-more {
  label {
    display: flex;
    align-items: center;
    height: 1.5em;
    margin: 0.2em;

    div {
      font-size: 0.7em;
    }
  }
}
</style>
